<template>
  <div :class="['odds-checker-table', {'fit-content': tableColumns.length < 6 && !isPlayerProps }]">
    <div class="odds-checker-table-header">
      <div
        :class="['odds-checker-table-header-column', column.columnType]"
        v-for="(column, index) in tableColumns"
        :key="index"
      >
        <div class="column-bottom">
          <div
            class="column-label"
            v-if="column.columnType !== 'market'"
          >
            {{ column.label }}
          </div>
          <div
            class="column-market"
            v-else
          >
            <Checkbox
              v-if="!isNil(column.isActive)"
              :model-value="column.isActive"
              @update:modelValue="toggleColumnVisibility(column)"
            />
            <div class="logo">
              <img
                :src="logo(column)"
                :alt="column.label"
                :title="column.label"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="odds-checker-table-body">
      <OddsCheckerTableRow
        v-for="(event, index) in events"
        :key="index"
        :event="event"
        :event-id="event.eventId"
        :table-columns="tableColumns"
        :is-player-props="isPlayerProps"
        :selected-market="selectedMarket"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { isNil, filter } from 'lodash';
import { findLogoByName } from '@/services/helpers/logos';
import Checkbox from '@/components/common/Checkbox';
import OddsCheckerTableRow from './OddsCheckerTableRow';

export default {
  components: {
    OddsCheckerTableRow,
    Checkbox,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    allTableColumns: {
      type: Array,
      required: true,
    },
    isPlayerProps: {
      type: Boolean,
      default: false,
    },
    selectedMarket: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const logo = (client) => {
      const operator = client?.operatorId;
      return operator ? findLogoByName(operator.toUpperCase()) : findLogoByName(client.id);
    };

    const toggleColumnVisibility = (column) => {
      store.dispatch('updateUserConfigurationBookmakers', {
        column: {
          ...column,
          isActive: !column.isActive,
        },
        type: 'isActive',
        allColumns: filter(props.allTableColumns, (c) => !isNil(c.isActive)),
      });
    };
    return {
      logo,
      toggleColumnVisibility,
      isNil,
    };
  },
};
</script>

<style lang="scss">
.odds-checker-table {
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 32px);
  overflow: auto;
  width: 100%;
  height: calc(100vh - 232px);
  overflow-y: auto;

  &.fit-content {
    width: fit-content;
    .odds-checker-table-header {
      min-width: unset;

      .odds-checker-table-row {
        min-width: unset;
      }
    }

  }

  .odds-checker-table-header {
    width: 100%;
    height: 44px;
    background-color: #FAFAFA;
    border-radius: 4px;
    display: flex;
    min-width: fit-content;
    position: sticky;
    top: 0;
    z-index: 100;

    .odds-checker-table-header-column {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 8px;
      color: #6D6D6D;
      min-width: 270px;

      &:first-child {
        position: sticky;
        left: 0;
        background-color: #FAFAFA;
        z-index: 99;
      }

      &:nth-child(2) {
        position: sticky;
        left: 110px;
        background-color: #FAFAFA;
        z-index: 99;
      }

      &:nth-child(3) {
        position: sticky;
        left: 470px;
        background-color: #FAFAFA;
        z-index: 99;
      }

      .column-bottom {
        height: 44px;
        display: flex;
        align-items: center;

        .column-market {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;

          .checkbox {
            display: flex;
            align-items: center;

            svg {
              path {
                stroke-width: 1;
              }
            }
          }

          .icon {
            width: 64px;
            height: 32px;
          }
        }
      }

      &.label-icon {
        width: 110px;
        max-width: 110px;
        min-width: 110px;
      }

      &.label-text {
        width: 360px;
        max-width: 360px;
        min-width: 360px;
      }
      &.market {
        width: 120px;
        max-width: 120px;
        min-width: 120px;
      }
    }
  }
}

</style>
