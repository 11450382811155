<template>
  <div
    class="columns-sidebar"
    @drop="onDrop()"
    @dragover.prevent
  >
    <div
      v-for="(column, index) in tableColumns"
      :key="index"
      class="column-item"
      draggable="true"
      @dragstart="onDrag($event, column)"
      @dragover="onDragOver(column)"
    >
      <Icon
        class="hamburger-bar-icon"
        name="selector-vertical"
      />
      <Checkbox
        :model-value="column.isActive"
        @update:modelValue="toggleColumnVisibility(column)"
      />
      <div class="column-label">
        {{ column.label }}
        <Tooltip
          class="columns-tooltip"
          v-if="duplicateFeed(column.label)"
          :text="columnSource(column)"
          right
        >
          <Icon
            name="alert-cycle"
          />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  filter, set, findIndex, isNil, orderBy,
} from 'lodash';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Checkbox,
    Tooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const tableColumns = computed(() => orderBy(filter(props.columns, (c) => !isNil(c.isActive))), 'position');
    const reorderContext = {};
    const onDrag = (event, column) => {
      set(event, 'dataTransfer.dropEffect', 'none');
      set(event, 'dataTransfer.effectAllowed', 'move');
      reorderContext.selected = '';
      reorderContext.target = '';
      reorderContext.selected = column;
    };
    const onDragOver = (column) => {
      reorderContext.target = column;
    };
    const onDrop = () => {
      const fromIndex = findIndex(tableColumns.value, (column) => column.position === reorderContext.selected.position);
      const toIndex = findIndex(tableColumns.value, (column) => column.position === reorderContext.target.position);
      store.dispatch('updateUserConfigurationBookmakers', {
        type: 'position',
        allColumns: tableColumns.value,
        from: fromIndex,
        to: toIndex,
      });
    };
    const toggleColumnVisibility = (column) => {
      store.dispatch('updateUserConfigurationBookmakers', {
        column: {
          ...column,
          isActive: !column.isActive,
        },
        type: 'isActive',
        allColumns: tableColumns.value,
      });
    };

    const duplicateFeed = (label) => filter(tableColumns.value, (column) => column.label === label).length > 1;
    const columnSource = (column) => {
      const { gateway } = column;
      return `Source: ${gateway === 'INTERNAL' ? 'HUDDLE' : gateway}`;
    };

    return {
      tableColumns,
      onDrag,
      onDragOver,
      onDrop,
      toggleColumnVisibility,
      duplicateFeed,
      columnSource,
    };
  },
};
</script>
<style lang="scss">
.columns-sidebar {
  height: 100%;
  padding: 20px 16px 0 16px;
  .column-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .hamburger-bar-icon {
      cursor: grab;
      svg {
        path {
          stroke-width: 0;
          fill: #A9A9A9;
        }
      }
    }
    .dragging {
      .hamburger-bar-icon {
        cursor: grabbing;
      }
    }

    .checkbox { margin-left: 14px; }
    .column-label {
      margin-left: 4px;
      display: flex;
      align-items: center;
      gap: 8px;

      .columns-tooltip {
        display: flex;

        .tooltip-element {
          display: flex;
          .icon {
            width: 16px;
            height: 16px;

            svg {
              path {
                stroke: #CDCDCD;
              }
            }
          }
        }
      }
    }
  }
}
</style>
